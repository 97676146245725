import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { logout } from "../../../actions/userActions";
import { useDispatch } from "react-redux";
import "./index.css"

const UserActivity = () => {
    // Get jsonwebtoken from browser storage
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    // State for how many page on the table list
    const [pageNumber, setPageNumber] = useState([])
    // State to show which page of table we are now
    const [currentPage, setCurrentPage] = useState(1)
    // State for activate which page is active and will get blue background
    const [isActive, setIsActive] = useState({ ods_1: true })
    // Pagination setting will send to backend as url query parameter to get club data
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 20
    })

    const [data, setData] = useState({
        uid: window.location.href.split("?")[1].split("&")[0].split("=")[1],
        startDate: window.location.href.split("?")[1].split("&")[1].split("=")[1],
        endDate: window.location.href.split("?")[1].split("&")[2].split("=")[1]
    })

    const { search, page, limit } = pagination

    const dispatch = useDispatch()

    // State as container or variable to receipt the club data from backend
    const [logs, setLogs] = useState([])

    // Config headers for send to backend
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    useEffect(() => {
        const getLogs = async () => {
            try {
                const logs = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/log/user?data=${JSON.stringify(data)}&page=${page}&limit=${limit}`, config)
                
                setPageNumber(Array(Math.ceil(logs.data.count / limit)).fill(0))
            
                setLogs(logs.data.rows)
            } catch (error) {
                // if (error.response.status === 401) {
                //     dispatch(logout())
                // }
            }
        }
        getLogs()
    }, [search, currentPage])

    const pageOnClick = (val) => {
        setCurrentPage(val)
        setPagination({ ...pagination, page: val })
        setIsActive({ [`ods_${val}`]: true })
    }

    // This function is for move forward when we click Next on pagination
    const onNextPage = () => {
        if (currentPage === pageNumber.length) {
            return
        }
        setCurrentPage(currentPage + 1)
        setPagination({ ...pagination, page: currentPage + 1 })
        setIsActive({ [`ods_${currentPage + 1}`]: true })
    }

    // This function is for move backforward when we click Previous on pagination
    const onPrevPage = () => {
        if (currentPage === 1) {
            return
        }
        setCurrentPage(currentPage - 1)
        setPagination({ ...pagination, page: currentPage - 1 })
        setIsActive({ [`ods_${currentPage - 1}`]: true })
    }

    const onChange = (e) => {
        setCurrentPage(1);
        setIsActive({ "ods_1": true })
        setPagination({ ...pagination, page: 1, search: e.target.value })
    }

    return (
        <div className="container-fluid loaded" style={{ display: "block" }}>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item"><Link to='/users/activity'>User Activity</Link></li>
                                <li className="breadcrumb-item active">User Activity List</li>
                            </ol>
                        </div>
                        <h4 className="page-title">User Activity</h4>
                    </div>
                </div>
            </div>
            <div className="form-messages"></div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="app-search search-form float-right">
                                <div className="form-group position-relative">
                                    <input
                                        type="text"
                                        name="edit[q]"
                                        className="form-control submittable"
                                        onChange={onChange}
                                        value={search}
                                        placeholder="Search by name..."
                                    />
                                    <span className="fa fa-search search-icon"></span>
                                </div>
                                <input type="hidden" name="op" value="" />
                            </div>
                            <h4 className="header-title mb-3 mt-1">User</h4>
                            <table id="tbl0" className="table">
                                <thead>
                                    <tr>
                                        <th width="84">User ID</th>
                                        <th>Name</th>
                                        <th>Page Visited</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logs.map(log => (
                                        <tr key={log.id}>
                                            <td>{log.id}</td>
                                            <td>{log.name}</td>
                                            <td>{log.page}</td>
                                            <td>{log.createdAt}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ maxWidth: '100%' }}>
                                <ul style={{ display:'flex', flexWrap:'wrap', listStyle:'none'}}>
                                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                        <a style={{ cursor: 'pointer' }} onClick={onPrevPage} className="page-link" tabIndex="-1">Previous</a>
                                    </li>
                                    {pageNumber.map((item, index) =>
                                    (
                                        <li key={index} className={`page-item ${isActive[`ods_${index + 1}`] ? "active" : ""}`}><a style={{ cursor: 'pointer' }} className="page-link" onClick={() => pageOnClick(index + 1)} >{index + 1}</a></li>
                                    ))}
                                    <li className={`page-item ${currentPage === pageNumber.length ? "disabled" : ""}`}>
                                        <a style={{ cursor: 'pointer' }} onClick={onNextPage} className="page-link">Next</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserActivity